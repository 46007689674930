'use strict';

window.ranger = window.ranger || {};

/**
 * Handles uploads of new pics.
 */
ranger.EVCalc = class {
    constructor() {
        this.playerInput = $('#heroSelect');

        this.potsize = $('#potsize');
        this.callsize = $('#callsize');
        this.shovesize = $('#shovesize');
        this.equity = $('#equity');
        this.foldfreq = $('#foldfreq');
        this.shoveCalc = $('#shoveCalc');
        this.calcPreEv = $('#calcPreEv');
        this.calculateRangeEquity = $('#calculateRangeEquity');

        this.potbefore = $('#potbefore');
        this.howmuchcall = $('#howmuchcall');
        this.callequity = $('#callequity');
        this.calcCallEv = $('#calcCallEv');

        this.randomize = $('#randomizerBtn');

        this.heroRange = $('.heroRangeSelect');
        this.villainRange = $('.villainRangeSelect');

        this.calcPreEv.click(() => this.calculateEV());
        this.calcCallEv.click(() => this.calculateCallEV());
        this.heroRange.click(() => this.selectRange('hero'));
        this.villainRange.click(() => this.selectRange('villain'));
        this.calculateRangeEquity.click(() => this.calculateEquity());
        this.randomize.click(() => this.randomizeFn());

        this.ctx = document.getElementById('equityscenarios').getContext('2d');

        /*this.data = [
          {equityReq80BP:
          {bounty: 1,
            stackSize: [{28: 44.9}, {25: 44.3}, {22.5: 43.7}, {20, 43}, {18:42.3}, {16:41.5}, {15:41}, {14:40.4}, {13:39.8}, {12:39}, {11:38.2}, {10: 37.2}, {9:36}, {8:34.7}]},
          {bounty: 1.5,
            stackSize: [{28: 43.9}, {25: 43.2}, {22.5: 42.5}, {20, 41.7}, {18:40.9}, {16:40}, {15:39.4}, {14:38.7}, {13:38}, {12:37.2}, {11:36.3}, {10: 35.2}, {9:34}, {8:32.5}]},
          {bounty: 2,
            stackSize: [{28: 42.9}, {25: 42.1}, {22.5: 41.4}, {20, 40.5}, {18:39.6}, {16:38.5}, {15:37.9}, {14:37.2}, {13:36.4}, {12:35.5}, {11:34.5}, {10: 33.4}, {9:32}, {8:30.5}]},
          {bounty: 2.5,
            stackSize: [{28: 42}, {25: 41.1}, {22.5: 40.3}, {20, 39.3}, {18:38.3}, {16:37.2}, {15:36.5}, {14:35.8}, {13:35}, {12:34}, {11:32.6}, {10: 31.8}, {9:30.4}, {8:28.8}]},
          {bounty: 3,
            stackSize: [{28: 41.1}, {25: 40.2}, {22.5: 39.3}, {20, 38.2}, {18:37.2}, {16:36}, {15:35.2}, {14:34.5}, {13:33.6}, {12:32.6}, {11:31.3}, {10: 30.3}, {9:28.9}, {8:27.2}]},
          {bounty: 3.5,
            stackSize: [{28: 40.2}, {25: 39.3}, {22.5: 38.3}, {20, 37.2}, {18:36.1}, {16:34.8}, {15:34}, {14:33.2}, {13:32.3}, {12:31.3}, {11:30}, {10: 28.9}, {9:27.5}, {8:25.8}]},
          {bounty: 4,
            stackSize: [{28: 39.4}, {25: 38.4}, {22.5: 37.4}, {20, 36.2}, {18:35}, {16:33.7}, {15:32.9}, {14:32}, {13:31.2}, {12:30}, {11:29}, {10: 27.7}, {9:26.2}, {8:24.5}]},
          {bounty: 4.5,
            stackSize: [{28: 38.6}, {25: 37.6}, {22.5: 36.5}, {20, 35.2}, {18:34}, {16:32.7}, {15:31.9}, {14:31}, {13:30}, {12:29}, {11:27.9}, {10: 26.5}, {9:25}, {8:23.4}]},
          {bounty: 5,
            stackSize: [{28: 37.9}, {25: 36.8}, {22.5: 35.7}, {20, 34.3}, {18:33.1}, {16:31.7}, {15:30.9}, {14:30}, {13:29}, {12:28}, {11:26.8}, {10: 25.5}, {9:24}, {8:22.4}]}
          },
          {equityDrop80BP:
            {bounty: 1,
              stackSize: [{28: 2.1}, {25, 2.4}, {22.5, 2.6}, {20, 2.9}, {18:3.2}, {16:3.4}, {15:3.6}, {14:3.8}, {13:4}, {12:4.3}, {11:4.5}, {10: 4.9}, {9:5.2}, {8:5.5}]},
            {bounty: 1.5,
              stackSize: [{28: 3.1}, {25, 3.5}, {22.5, 3.8}, {20, 4.2}, {18:4.6}, {16:4.9}, {15:5.2}, {14:5.5}, {13:5.8}, {12:6.1}, {11:6.4}, {10: 6.9}, {9:7.2}, {8:7.7}]},
            {bounty: 2,
              stackSize: [{28: 4.1}, {25, 4.6}, {22.5, 4.9}, {20, 5.4}, {18:5.9}, {16:6.4}, {15:6.7}, {14:7}, {13:7.4}, {12:7.8}, {11:8.2}, {10: 8.7}, {9:9.2}, {8:9.7}]},
            {bounty: 2.5,
              stackSize: [{28: 5}, {25, 5.6}, {22.5, 6}, {20, 6.6}, {18:7.2}, {16:7.7}, {15:8.1}, {14:8.4}, {13:8.8}, {12:9.3}, {11:9.7}, {10: 10.3}, {9:10.8}, {8:11.4}]},
            {bounty: 3,
              stackSize: [{28: 5.9}, {25, 6.5}, {22.5, 7}, {20, 7.7}, {18:8.3}, {16:8.9}, {15:9.4}, {14:9.7}, {13:10.2}, {12:10.7}, {11:11.2}, {10: 11.8}, {9:12.3}, {8:13}]},
            {bounty: 3.5,
              stackSize: [{28: 6.8}, {25, 7.4}, {22.5, 8}, {20, 8.7}, {18:9.4}, {16:10.1}, {15:10.6}, {14:11}, {13:11.5}, {12:12}, {11:12.5}, {10: 13.2}, {9:13.7}, {8:14.4}]},
            {bounty: 4,
              stackSize: [{28: 7.6}, {25, 8.3}, {22.5, 8.9}, {20, 9.7}, {18:10.5}, {16:11.2}, {15:11.7}, {14:12.2}, {13:12.6}, {12:13.3}, {11:13.7}, {10: 14.4}, {9:15}, {8:15.7}]},
            {bounty: 4.5,
              stackSize: [{28: 8.4}, {25, 9.1}, {22.5, 9.8}, {20, 10.7}, {18:11.5}, {16:12.2}, {15:12.7}, {14:13.2}, {13:13.8}, {12:14.3}, {11:14.8}, {10: 15.6}, {9:16.2}, {8:16.8}]},
            {bounty: 5,
              stackSize: [{28: 9.1}, {25, 9.9}, {22.5, 10.6}, {20, 11.6}, {18:12.4}, {16:13.2}, {15:13.7}, {14:14.2}, {13:14.8}, {12:15.3}, {11:15.9}, {10: 16.6}, {9:17.2}, {8:17.8}]}
          }
        ]*/

        $(document).ready(() => {
          /*var isHighlighted;
          var isMouseDown;
          $(".selectRange")
            .mousedown(function() {
              isMouseDown = true;
                  $(this).toggleClass('mdl-color--orange');
                  isHighlighted = $(this).hasClass('mdl-color--orange');
              return false; // prevent text selection
            })
            .mouseover(function() {
              if (isMouseDown) {
                    $(this).toggleClass('mdl-color--orange', isHighlighted);
              }
            })
            .bind("selectstart", function() {
              return false;
            })
            .mouseup(function() {
                isMouseDown = false;
              ranger.evCalc.updateInput();
            })*/
        });

    }


    loadEV() {
        console.log('EV Loaded');
    }

    randomizeFn() {
      $('.randomResult').removeClass('mdl-color--red mdl-color--green');
      var number = Math.floor(Math.random() * (100 - 0)) + 0;
      if (number <= $('#randomInputSldr').val()) {
        var html = '<span>' + number + '</span>'
        var colour ="mdl-color--green";
      } else {
        var html = '<span>' + number + '</span>'
        var colour = "mdl-color--red";
      }
      $('.randomResult').addClass(colour).html(html);
    }

    /*calculateEquity() {
        var $player1 = $('#heroSelect').val();
        $player1 = $player1.replace(/\s/g, '');
        var $player2 = $('#villainSelect').val();
        $player2 = $player2.replace(/\s/g, '');
        var $board = $('#selectBoard').val();
        $board = $board.trim();
        console.log($player1);
        console.log($player2);
        console.log($board);

        $('#p2').show();

        window.worker.onmessage = function(e) {
          $('#p2').hide();
          var heroEquity = (e.data.results[0]._winShares + e.data.results[0]._tieShares) / e.data.ttl * 100;
          var villainEquity = (e.data.results[1]._winShares + e.data.results[1]._tieShares) / e.data.ttl * 100;

          $('#heroEquityTwo').text('Hero Equity ' + heroEquity.toFixed(2) + '%');
          $('#villainEquityTwo').text('Villain Equity ' + villainEquity.toFixed(2) + '%');
        }

        window.worker.postMessage([$player1, $player2, $board]);
    }

    selectRange(player) {
        if (player === 'hero') {
            this.playerInput = $('#heroSelect');
            $('#selectRangeTable').show();
        } else {
            this.playerInput = $('#villainSelect');
            $('#selectRangeTable').show();
        }
        $('.selectRange.mdl-color--orange').removeClass('mdl-color--orange');
    }

    updateInput() {
        var hands = [];
      $('.selectRange.mdl-color--orange').each(function() {
         hands.push( $(this).html() );
      });
        this.playerInput.val(hands);
        this.playerInput.parent().addClass('is-dirty');
        if (hands.length < 1) {
            this.playerInput.parent().removeClass('is-dirty');
        }
    }*/

    calculateEV(){
        var equity = parseFloat(this.equity.val() / 100);
        var equityMT = parseFloat(equity - 0.1);
        var equityMF = parseFloat(equity - 0.05);
        var equityMTT = parseFloat(equity + 0.1);
        var equityMFF = parseFloat(equity + 0.05);

        var foldfreq = parseFloat(this.foldfreq.val() / 100);
        var foldfreqMT = parseFloat(foldfreq - 0.1);
        var foldfreqMF = parseFloat(foldfreq - 0.05);
        var foldfreqMTT = parseFloat(foldfreq + 0.1);
        var foldfreqMFF = parseFloat(foldfreq + 0.05);


        var potsize = parseFloat(this.potsize.val());
        var shovesize = parseFloat(this.shovesize.val());
        var callsize = parseFloat(this.callsize.val());

        //(foldfreq * potsize) + ((1-foldfreq)*equity*(shovesize+potsize)) - ((1-foldfreq)*(1-equity)*shovesize);
        var actualEV = (foldfreq * potsize) + ((1-foldfreq)*equity*(shovesize+potsize)) - ((1-foldfreq)*(1-equity)*shovesize);

        //adjust fold equity
        if (foldfreqMT >= 0) {
        var EVFET = (foldfreqMT * potsize) + (1-foldfreqMT)*(equity*(shovesize+potsize)) - ((1-foldfreq)*(1-equity)*shovesize);
        } else { var EVFET = 0; }
        if (foldfreqMF >= 0) {
        var EVFEF = (foldfreqMF * potsize) + (1-foldfreqMF)*(equity*(shovesize+potsize)) - ((1-foldfreq)*(1-equity)*shovesize);
        } else { var EVFEF = 0; }
        if(foldfreqMTT >= 0) {
        var EVFETT = (foldfreqMTT * potsize) + (1-foldfreqMTT)*(equity*(shovesize+potsize)) - ((1-foldfreq)*(1-equity)*shovesize);
        } else { var EVFETT = 0; }
        if(foldfreqMFF >= 0) {
        var EVFEFF = (foldfreqMFF * potsize) + (1-foldfreqMFF)*(equity*(shovesize+potsize)) - ((1-foldfreq)*(1-equity)*shovesize);
        } else { var EVFEFF = 0; }

        //adjust our equity
        if (equityMT >= 0) {
        var EVET = (foldfreq * potsize) + ((1-foldfreq)*equity*(shovesize+potsize)) - ((1-foldfreq)*(1-equity+0.1)*shovesize);
        } else { var EVET = 0; }
        if (equityMF >= 0) {
        var EVEF = (foldfreq * potsize) + ((1-foldfreq)*equity*(shovesize+potsize)) - ((1-foldfreq)*(1-equity+0.05)*shovesize);
        } else { var EVEF = 0; }
        if (equityMTT >= 0) {
        var EVETT = (foldfreq * potsize) + ((1-foldfreq)*equity*(shovesize+potsize)) - ((1-foldfreq)*(1-equity-0.1)*shovesize);
        } else { var EVETT = 0}
        if (equityMFF >= 0) {
        var EVEFF = (foldfreq * potsize) + ((1-foldfreq)*equity*(shovesize+potsize)) - ((1-foldfreq)*(1-equity-0.05)*shovesize);
        } else { var EVEFF = 0; }
        actualEV = Math.round(actualEV * 100) / 100;

        if (actualEV > 0) {
            var evColour = 'mdl-color-text--orange';
        } else {
            var evColour = 'mdl-color-text--red';
        }
        $('.preEv').addClass(evColour).html(actualEV);


        var data = [EVFET, EVET, EVFEF, EVEF, actualEV, EVEFF, EVFEFF, EVETT, EVFETT];

        var colours = data.map((value) => value < 0 ? '#ff0101' : '#ff9800');

        try {
            window.evChart.destroy();
        }
        catch (error) {
            console.log(error);
        }
        window.evChart = new Chart(this.ctx, {
           type: 'bar',
           data: {
            labels: [parseInt(this.foldfreq.val()) - 10 + "% Folds", parseInt(this.equity.val()) - 10 + "% Equity", parseInt(this.foldfreq.val()) - 5 + "% Folds", parseInt(this.equity.val()) - 5 + "% Equity", "Actual", parseInt(this.equity.val()) + 5 + "% Equity", parseInt(this.foldfreq.val()) + 5 + "% Folds", parseInt(this.equity.val()) + 10 + "% Equity", parseInt(this.foldfreq.val()) + 10 + "% Folds"],
            datasets: [{
                label: 'EV',
                data: data,
                backgroundColor: colours
            }]
           },
           options: {
            title: {
              display: true,
              text: '+/- 10% Scenarios'
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero:true
                    }
                }]
            }
           }
        });
    }

    calculateCallEV() {
        var potbefore = parseFloat(this.potbefore.val());
        var howmuchcall = parseFloat(this.howmuchcall.val());
        var callequity = parseFloat(this.callequity.val() / 100);

        var callEV = (potbefore*callequity)-(howmuchcall*(1-callequity));
        callEV = Math.round(callEV * 100) / 100;

        if (callEV > 0) {
            var evColour = 'mdl-color-text--orange';
        } else {
            var evColour = 'mdl-color-text--red';
        }
        $('.callEv').addClass(evColour).html(' $' + callEV);


    }
};
ranger.evCalc = new ranger.EVCalc();
